import { Injectable } from '@angular/core';

import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Observable,
} from 'rxjs';

import { Store } from '@ngrx/store';
import { RouterUrlCurrentService } from '@ts/shared/router/util-url';
import { selectRouteParams } from '@ts/shared/store/data-access-router';

@Injectable({
  providedIn: 'root',
})
export class RouteParamsService {
  getRouteParamDebounceTimeMs = 100;

  constructor(
    private store: Store,
    private routerUrlCurrentService: RouterUrlCurrentService,
  ) {}

  getRouteParam$(paramName: string): Observable<string> {
    const routeConfigs = this.routerUrlCurrentService.getRouteConfigs();

    return this.store.select(selectRouteParams).pipe(
      debounceTime(this.getRouteParamDebounceTimeMs),
      filter(() =>
        this.routerUrlCurrentService.isCurrentRouteConfigsEqual(routeConfigs),
      ),
      map((routeParams) => routeParams[paramName]),
      filter((param) => param !== undefined),
      distinctUntilChanged(),
    );
  }
}
