import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'ts-ion-form-field-wrapper-ui',
  templateUrl: './ion-form-field-wrapper-ui.component.html',
  styleUrls: ['./ion-form-field-wrapper-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IonFormFieldWrapperUiComponent extends FieldWrapper {}
