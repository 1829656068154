import { Injectable } from '@angular/core';

import { isEqual } from 'lodash';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Observable,
} from 'rxjs';

import { Store } from '@ngrx/store';
import { RouterUrlCurrentService } from '@ts/shared/router/util-url';
import { selectQueryParams } from '@ts/shared/store/data-access-router';

@Injectable({
  providedIn: 'root',
})
export class QueryParamsService {
  getQueryParamsDebounceTimeMs = 100;
  getQueryParamDebounceTimeMs = 100;

  constructor(
    private store: Store,
    private routerUrlCurrentService: RouterUrlCurrentService,
  ) {}

  private getQueryParamsRaw$(
    debounceTimeMs: number,
  ): Observable<Record<string, string>> {
    const routeConfigs = this.routerUrlCurrentService.getRouteConfigs();
    return this.store.select(selectQueryParams).pipe(
      debounceTime(debounceTimeMs),
      filter(() =>
        this.routerUrlCurrentService.isCurrentRouteConfigsEqual(routeConfigs),
      ),
    );
  }

  getQueryParams$(): Observable<Record<string, string>> {
    return this.getQueryParamsRaw$(this.getQueryParamsDebounceTimeMs).pipe(
      distinctUntilChanged(isEqual),
    );
  }

  getQueryParam$(paramName: string): Observable<string | undefined> {
    return this.getQueryParamsRaw$(this.getQueryParamDebounceTimeMs).pipe(
      map((queryParams) => queryParams[paramName]),
      distinctUntilChanged(isEqual),
    );
  }
}
