<ion-item lines="none" *ngIf="props['helpComponent']">
  <ion-label>
    <ng-template #helpTextContainer></ng-template>
  </ion-label>
</ion-item>
<ion-item lines="none" *ngIf="props.description">
  <ion-label>
    <p>
      {{ props.description }}
    </p>
  </ion-label>
</ion-item>
