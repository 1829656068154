import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';

import { AnalyticsWrapperService } from '@ts/analytics/shared/data-access';
import { AuthenticationService } from '@ts/shared/authentication/data-access';

import { initUserService } from './user-init';
import { UserStreamService } from './user-stream.service';

@NgModule({
  imports: [CommonModule],
})
export class UserSharedDataAccessModule {
  static forRoot(): ModuleWithProviders<UserSharedDataAccessModule> {
    return {
      ngModule: UserSharedDataAccessModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: initUserService,
          deps: [
            AuthenticationService,
            UserStreamService,
            AnalyticsWrapperService,
          ],
          multi: true,
        },
      ],
    };
  }
}
