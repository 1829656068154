<ts-ion-form-field-label-ui [to]="to"></ts-ion-form-field-label-ui>
<ion-input
  #mapSearchField
  type="text"
  [formControl]="formControl"
  [placeholder]="placeholder"
  [ionFormlyAttributes]="field"
  (ionBlur)="inputBlur()"
></ion-input>
<ts-ion-form-field-validation-message-ui
  [showError]="showError"
  [field]="field"
></ts-ion-form-field-validation-message-ui>

<google-map
  width="100%"
  height="100%"
  [options]="googleMapOptions"
  [ngClass]="getClass()"
>
  <ng-container *ngIf="markerPosition">
    <map-marker
      [position]="markerPosition"
      [options]="markerOptions"
    ></map-marker>
  </ng-container>
</google-map>
