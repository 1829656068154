import { Injectable } from '@angular/core';

import { map, Observable, shareReplay, switchMap, take } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { EnvironmentService } from '@ts/shared/util-environment';
import { ScriptLoaderService } from '@ts/shared/util-script';

import { ReCaptcha } from './grecaptcha.model';

declare global {
  interface Window {
    ng_g_recaptcha_loaded: () => void;
  }
}

/**
 * For lazy loading recaptcha API.
 */
@Injectable({
  providedIn: 'root',
})
export class RecaptchaScriptLoaderService {
  apiUrl =
    'https://www.google.com/recaptcha/enterprise.js?render=%s&onload=ng_g_recaptcha_loaded';

  grecaptcha$: Observable<ReCaptcha>;

  constructor(
    scriptLoaderService: ScriptLoaderService,
    environmentService: EnvironmentService,
  ) {
    const siteKey = environmentService.get().recaptchaSiteKey;

    this.grecaptcha$ = new Observable<boolean>((subscriber) => {
      scriptLoaderService.loadManual({ url: sprintf(this.apiUrl, siteKey) });

      window.ng_g_recaptcha_loaded = () => {
        subscriber.next(true);
        subscriber.complete();
      };
    }).pipe(
      // We add another take(1) and shareReplay(1) so the script won't be re-inserted
      // when the captcha engine is waiting until it is ready.
      take(1),
      shareReplay(1),
      map(() => this.getGrecaptcha()),
      switchMap(
        (grecaptcha) =>
          new Observable<ReCaptcha>((subscriber) => {
            grecaptcha.ready(() => subscriber.next(grecaptcha));
          }),
      ),
      take(1),
      shareReplay(1),
    );
  }

  /**
   * Wrapper to obtain the google recaptcha object.
   */
  private getGrecaptcha(): ReCaptcha {
    return (window as unknown as { grecaptcha: { enterprise: ReCaptcha } })
      .grecaptcha.enterprise;
  }
}
