import { Injectable } from '@angular/core';

import { environment } from './environment';
import { Environment } from './environment.model';

/**
 * Wrapper for retrieving environment variables, for easier testing.
 */
@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  get(): Environment {
    return environment;
  }
}
