import { Injectable } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';

import { strict as assert } from 'assert';

type RouteConfigs = Array<Route | null>;

@Injectable({
  providedIn: 'root',
})
export class RouterUrlCurrentService {
  constructor(private router: Router) {}

  isCurrentRouteConfigsEqual(configs: RouteConfigs): boolean {
    const currentConfigs = this.getRouteConfigs();

    // This is just array shallow equality testing that strangely not provided by lodash.
    if (currentConfigs.length !== configs.length) return false;
    for (let i = 0; i < currentConfigs.length; ++i) {
      if (currentConfigs[i] !== configs[i]) {
        return false;
      }
    }
    return true;
  }

  getRouteConfigs(): RouteConfigs {
    let currentRoute: ActivatedRoute | null = this.router.routerState.root;
    const states: ActivatedRoute[] = [];

    while (currentRoute) {
      states.push(currentRoute);
      assert(currentRoute.children.length <= 1);
      currentRoute = currentRoute.firstChild;
    }

    return states.map((state) => state.routeConfig);
  }
}
