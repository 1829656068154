import { Injectable } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';
import { firstValueFrom } from 'rxjs';

import { TranslocoService } from '@ngneat/transloco';
import { Language } from '@ts/shared/i18n/util-constants';

export const COOKIE_LANGUAGE = 'I18N_LANGUAGE';

/**
 * Wrapper for TranslocoService. Use this instead of directly using
 * TranslocoService inside APIs.
 */
@Injectable({
  providedIn: 'root',
})
export class I18nService {
  cookieLanguageValidityDays = 30;

  constructor(
    private translocoService: TranslocoService,
    private cookieService: CookieService,
  ) {}

  /**
   * Translates a given translation key.
   *
   * @param translationKey translation key in the format of scope.value
   */
  translate$(
    translationKey: string,
    // we are following the API from translocoService here
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    translationParams?: Record<string, any>,
  ): Promise<string> {
    const words = translationKey.split('.');
    if (words.length < 2) {
      throw new Error(
        `Translation Key must be of the form scope.value, but found "${translationKey}".`,
      );
    }
    return firstValueFrom(
      this.translocoService.selectTranslate(
        words.slice(1).join('.'),
        translationParams,
        {
          scope: words[0],
        },
      ),
    );
  }

  /**
   * Translates several keys at once that shares a certain prefix.
   *
   * For example, you can translate "a.b.name" and "a.b.description" by invoking:
   *
   * ```
   * translateObject$<{name: string, description: string}>('a.b', {'name': true, 'description: true})
   * ```
   *
   * @param prefix The prefix for each translation key
   * @param fields Map of the translation keys
   */
  async translateObject$<T extends { [key: string]: string }>(
    prefix: string,
    fields: { [key in keyof T]: true },
  ): Promise<T> {
    const t: { [key: string]: string } = {};
    for (const field of Object.keys(fields)) {
      t[field] = await this.translate$(`${prefix}.${field}`);
    }
    return t as T;
  }

  getLanguageActive(): Language {
    return this.translocoService.getActiveLang() as Language;
  }

  setLanguageActive(language: Language) {
    this.translocoService.setActiveLang(language);

    this.cookieService.set(
      COOKIE_LANGUAGE,
      language,
      /* expires (days) = */ this.cookieLanguageValidityDays,
      /* path =*/ '/',
      /* domain = */ undefined,
      /* secure = */ true,
      /* sameSite = */ 'None',
    );
  }

  loadLanguageFromCookie() {
    if (this.cookieService.check(COOKIE_LANGUAGE)) {
      const language = this.cookieService.get(COOKIE_LANGUAGE) as Language;
      this.setLanguageActive(language);
    }
  }
}
