import { CommonModule, DOCUMENT } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';

import { I18nService } from '@ts/shared/18n/util-core';
import { UserStreamService } from '@ts/user/shared/data-access';

import { initI18nService } from './i18n-init';

@NgModule({
  imports: [CommonModule],
})
export class SharedI18nDataAccessModule {
  static forRoot(): ModuleWithProviders<SharedI18nDataAccessModule> {
    return {
      ngModule: SharedI18nDataAccessModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: initI18nService,
          deps: [UserStreamService, I18nService, DOCUMENT],
          multi: true,
        },
      ],
    };
  }
}
