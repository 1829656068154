import { Observable, shareReplay } from 'rxjs';

import { ApiEntityStreamAbstractService } from './api-entity-stream-abstract.service';

/**
 * Abstracts retrieving an entity from an API, and make the data persistent.
 *
 * Compared to ApiEntityStreamAbstractService, this one will keep the data around
 * even when there are no subscribers.
 */
export abstract class ApiEntityStreamPersistentAbstractService<
  T,
> extends ApiEntityStreamAbstractService<T> {
  protected override getEntity$(): Observable<T | null> {
    return this.baseEntity$.pipe(shareReplay(1));
  }
}
