import { HttpErrorResponse } from '@angular/common/http';

export type BormaDagoErrorCode =
  | 'authentication_failed'
  | 'invalid'
  | 'not_authenticated'
  | 'permission_denied'
  | 'token_not_valid'
  | 'two_factor_required';

// this constant is actually too close to a type, hence we put it in this file instead.
export const BORMA_DAGO_ERROR_CODE_FORM_INVALID: BormaDagoErrorCode = 'invalid';

export interface BormaDagoError {
  detail: string;
  error_code?: BormaDagoErrorCode;
  [errorKeyword: string]: string | string[] | undefined;
}

export interface BormaDagoHttpErrorResponse extends HttpErrorResponse {
  error: BormaDagoError;
}
