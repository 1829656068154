import { Injectable } from '@angular/core';

import { camelCase } from 'lodash';

import { OrderStatus } from '@ts/order/shared/util-core';
import { I18nService } from '@ts/shared/18n/util-core';

@Injectable({
  providedIn: 'root',
})
export class OrderStatusI18nService {
  constructor(private i18nService: I18nService) {}

  translate$(orderStatus: OrderStatus): Promise<string> {
    return this.i18nService.translate$(
      `order.status.${camelCase(orderStatus)}`,
    );
  }
}
