import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

import { I18nService } from '@ts/shared/18n/util-core';

import { DEFAULT_TITLE } from './title.service';

export type MetaTagName = 'description';

const META_TAG_NAME_DICT: {
  [metaTagName in MetaTagName]: null;
} = { description: null };

export interface MetaTagParams {
  metaTagName: MetaTagName;
  contentTranslationKey: string;
  translationContext?: Record<string, string>;
}

@Injectable({
  providedIn: 'root',
})
export class BormadagoMetaTagStrategyService {
  META_TAG_NAMES = Object.keys(META_TAG_NAME_DICT) as readonly MetaTagName[];

  constructor(
    private readonly metaService: Meta,
    private i18nService: I18nService,
  ) {}

  setMetaTag(params: MetaTagParams) {
    this.translateContent$(params).then((content) => {
      const param = {
        name: params.metaTagName,
        content,
      } as const;

      this.metaService.getTag(this.getAttributeSelector(params.metaTagName))
        ? this.metaService.updateTag(param)
        : this.metaService.addTag(param);
    });
  }

  removeMetaTag(name: MetaTagName) {
    this.metaService.removeTag(this.getAttributeSelector(name));
  }

  private getAttributeSelector(key: string): string {
    return `name=${key}`;
  }

  private async translateContent$(metaParams: MetaTagParams): Promise<string> {
    const content = await this.i18nService.translate$(
      metaParams.contentTranslationKey,
      metaParams.translationContext,
    );

    return `${content} | ${DEFAULT_TITLE}`;
  }
}
