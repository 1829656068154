<ng-container *tsAwaitSpinner="isScriptLoaded$ | async">
  <ion-item>
    <ts-address-ui-field
      (placeSelected)="placeSelected($event)"
      [addressFieldModelInitial]="addressFieldModelInitial"
      [to]="to"
      [field]="field"
    ></ts-address-ui-field>
  </ion-item>
</ng-container>
