import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';
import { FormlyPresetModule } from '@ngx-formly/core/preset';
import { OrderStatusI18nService } from '@ts/order/shared/util-status-i18n';

import { registerOrderStatusPreset } from './order-status-preset-register';

@NgModule({
  imports: [CommonModule, IonicModule, FormlyPresetModule, FormlyModule],
  providers: [
    { provide: OrderStatusI18nService },
    {
      provide: FORMLY_CONFIG,
      useFactory: registerOrderStatusPreset,
      deps: [OrderStatusI18nService],
      multi: true,
    },
  ],
})
export class OrderFilterUiStatusFormModule {}
