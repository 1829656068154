import { Component, Input, OnInit } from '@angular/core';

import { FormlyFieldConfig } from '@ngx-formly/core';
import { IonFormLabelPosition } from '@ts/shared/util-ionic';

@Component({
  selector: 'ts-ion-form-field-label-ui',
  templateUrl: './ion-form-field-label-ui.component.html',
  styleUrls: ['./ion-form-field-label-ui.component.scss'],
  // can't use onpush because to.label can change.
})
export class IonFormFieldLabelUiComponent implements OnInit {
  @Input() to!: NonNullable<Required<FormlyFieldConfig>['props']>;
  labelPosition!: IonFormLabelPosition;
  labelPositionDefault = 'stacked';

  ngOnInit() {
    this.labelPosition = this.to['labelPosition'] || this.labelPositionDefault;
  }
}
