import { HasDateCreatedTimestamp } from '@ts/shared/date-time/util-core';
import { User } from '@ts/user/shared/util-core';

export type OrderStatus =
  | 'Ready for payment'
  | 'Awaiting payment'
  | 'Payment failure'
  | 'Paid'
  | 'Ready for shipping'
  | 'Awaiting shipping'
  | 'Shipping failure'
  | 'In shipping'
  | 'Cancelled'
  | 'Complete';

export const ORDER_STATUSES: OrderStatus[] = [
  'Ready for payment',
  'Awaiting payment',
  'Payment failure',
  'Paid',
  'Ready for shipping',
  'Awaiting shipping',
  'Shipping failure',
  'In shipping',
  'Cancelled',
  'Complete',
];

interface OrderStatusEventBase extends HasDateCreatedTimestamp {
  status_before: OrderStatus;
  status_after: OrderStatus;
  notes: string;
  user: User;
}

export type OrderStatusEvent = Readonly<OrderStatusEventBase>;

export interface OrderStatusChangeParams {
  status_from: OrderStatus;

  // new status
  status: OrderStatus;

  note: string;
}
