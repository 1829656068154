import { NgModule } from '@angular/core';

import {
  provideTransloco,
  provideTranslocoLoader,
  provideTranslocoLoadingTpl,
  provideTranslocoMissingHandler,
  TranslocoModule,
} from '@ngneat/transloco';
import { SharedDateTimeUtilShellModule } from '@ts/shared/date-time/util-shell';
import { LANGUAGE_DEFAULT, LANGUAGES } from '@ts/shared/i18n/util-constants';
import { I18nMissingHandler } from '@ts/shared/i18n/util-missing-handler';
import { SpinnerUiComponent } from '@ts/shared/ui-await-spinner';
import { environment } from '@ts/shared/util-environment';

import { TranslocoHttpLoader } from './transloco-http-loader.service';

/**
 * Initializes Internationalization.
 *
 * Should only be imported at the root.
 */
@NgModule({
  imports: [SharedDateTimeUtilShellModule],
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: [...LANGUAGES],
        defaultLang: LANGUAGE_DEFAULT,
        fallbackLang: LANGUAGE_DEFAULT,
        reRenderOnLangChange: false,
        prodMode: environment.production,
      },
    }),
    provideTranslocoMissingHandler(I18nMissingHandler),
    provideTranslocoLoader(TranslocoHttpLoader),
    provideTranslocoLoadingTpl(SpinnerUiComponent),
  ],
})
export class I18nRootShellModule {}
