import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import {
  Currency,
  CURRENCY_DEFAULT,
  MoneyFormatService,
  MoneyOperationService,
} from '@ts/shared/money/util-core';

@Component({
  selector: 'ts-money-field-ui',
  templateUrl: './money-field-ui.component.html',
  styleUrls: ['./money-field-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoneyFieldUiComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  currency: Currency = CURRENCY_DEFAULT;

  currencyDisplay!: string;

  amountFormControl!: FieldType<FieldTypeConfig>['formControl'];

  constructor(
    private moneyFormatService: MoneyFormatService,
    private moneyOperationService: MoneyOperationService,
  ) {
    super();
  }

  ngOnInit() {
    this.amountFormControl = new FormControl<number>(
      this.formControl.value?.amount,
    );
    this.currencyDisplay =
      this.moneyFormatService.CURRENCY_TO_DISPLAY[this.currency];
  }

  inputChanged() {
    const value = this.amountFormControl.value;
    if (value !== undefined && value !== null) {
      this.formControl.setValue(
        this.moneyOperationService.numberToMoney(
          this.amountFormControl.value,
          this.currency,
        ),
      );
    } else {
      this.formControl.setValue(null);
    }
  }
}
