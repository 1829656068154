import { from, Observable } from 'rxjs';

import { FormlyFieldConfig } from '@ngx-formly/core';
import { I18nService } from '@ts/shared/18n/util-core';

/**
 * formly extension to support i18n
 *
 * See https://formly.dev/examples/advanced/i18n-alternative
 */
// We test this extension in `shared/form/ui-core/src/lib/18n.extension.spec.ts`
// to avoid circular library dependency.
export class I18nExtension {
  TRANSLATED_FIELDS = ['label', 'placeholder', 'description'];

  constructor(private i18nService: I18nService) {}

  prePopulate(field: FormlyFieldConfig) {
    const to = field.props || {};
    if (!to['i18n'] || to['_translated']) {
      return;
    }

    to['_translated'] = true;

    const expressions: Record<string, Observable<string>> = {};

    for (const field of this.TRANSLATED_FIELDS) {
      if (to[field]) {
        expressions[`props.${field}`] = from(
          this.i18nService.translate$(to[field]),
        );
      }
    }

    field.expressions = {
      ...(field.expressions || {}),
      ...expressions,
    };
  }
}

/**
 * List of errors to be validated.
 *
 * Their translation will be checked in sharedForm.validation.{key}
 *
 * E.g., 'required' becomes 'sharedForm.validation.required'
 */
export const VALIDATION_MESSAGES = [
  'addressInvalid',
  'email',
  'invalidPhoneNumber',
  'max',
  'maxLength',
  'min',
  'minLength',
  'otp',
  'required',
];

export function registerTranslateExtension(i18nService: I18nService) {
  return {
    validationMessages: VALIDATION_MESSAGES.map((key) => {
      return {
        name: key,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        message(error: any, field: FormlyFieldConfig) {
          return from(
            i18nService.translate$(`sharedForm.validation.${key}`, {
              max: field.props?.max,
              maxLength: field.props?.maxLength,
              min: field.props?.min,
              minLength: field.props?.minLength,
            }),
          );
        },
      };
    }),
    extensions: [
      {
        name: 'i18n',
        extension: new I18nExtension(i18nService),
      },
    ],
  };
}
