import { Injectable } from '@angular/core';
import { BormaDagoApiConsumerService } from '@ts/shared/api/data-access-borma-dago-api-consumer';
import { AuthenticationService } from '@ts/shared/authentication/data-access';

import { BormaDagoAuthenticatedApiConsumerService } from './borma-dago-api-authenticated-consumer.service';

/**
 * Calls Borma Dago API with authentication information attached.
 *
 * If the user is not authenticated, the request will be retried later when they finally log in.
 */
@Injectable({
  providedIn: 'root',
})
export class BormaDagoPersistentAuthenticatedApiConsumerService extends BormaDagoAuthenticatedApiConsumerService {
  constructor(
    bormaDagoApiConsumerService: BormaDagoApiConsumerService,
    authenticationService: AuthenticationService,
  ) {
    super(bormaDagoApiConsumerService, authenticationService);
  }

  protected override getAuthenticationState$(): AuthenticationService['authenticationState$'] {
    return this.authenticationService.authenticationState$;
  }
}
