import { NgModule } from '@angular/core';

import { OrderFilterUiStatusFormModule } from '@ts/order/filter/ui-status-form';
import {
  getAppImports,
  getAppProviders,
} from '@ts/shared/feature-apps-bootstrap';
import { SharedFormUiDateTimeModule } from '@ts/shared/form/ui-date-time';
import { SharedFormUiGoogleMapsModule } from '@ts/shared/form/ui-google-maps';
import { SharedMoneyFormUiFieldModule } from '@ts/shared/money/form/ui-field';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    ...getAppImports(),
    // ==================
    //   SHARED MODULES
    // ==================
    // TODO(irvan): lazy load this module:
    SharedFormUiGoogleMapsModule,
    SharedMoneyFormUiFieldModule,
    SharedFormUiDateTimeModule,
    OrderFilterUiStatusFormModule,
  ],
  providers: [...getAppProviders()],
  bootstrap: [AppComponent],
})
export class AppModule {}
