import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControlStatus, UntypedFormControl } from '@angular/forms';
import { GoogleMap } from '@angular/google-maps';

import { strict as assert } from 'assert';
import { Subscription } from 'rxjs';

import { IonInput } from '@ionic/angular';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { LatLngLiteral } from '@ts/address/shared/util-core';

import { AddressFieldModel } from '../address.field.model';

/**
 * We do not set the map location to the user's current location
 * because the location provided by the google maps API is not accurate.
 * That is because the google maps API returns the location
 * of the user's providers instead of the actual device location.
 */
@Component({
  selector: 'ts-address-ui-field',
  styleUrls: ['./address-ui.field.component.scss'],
  templateUrl: './address-ui.field.component.html',
  // can't use onpush because to.label can change.
})
export class AddressUiFieldComponent
  implements AfterViewInit, OnDestroy, OnInit
{
  /**
   * Initial address, if any
   */
  @Input() addressFieldModelInitial?: AddressFieldModel;

  /**
   * Template options from formly
   */
  @Input() to!: FieldType<FieldTypeConfig>['props'];

  /**
   * Field from formly
   */
  @Input() field!: FieldType<FieldTypeConfig>['field'];

  /**
   * Emitted whenever the user input a new address.
   *
   * Emits null if the user inputs an invalid address.
   */
  @Output() placeSelected =
    new EventEmitter<google.maps.places.PlaceResult | null>();

  @ViewChild('mapSearchField', { static: false }) searchField!: IonInput;
  @ViewChild(GoogleMap, { static: false }) map!: GoogleMap;

  placeholder = 'Jl. Asia Afrika...';

  googleMapOptions: google.maps.MapOptions = {
    gestureHandling: 'none',
  };

  formControl!: FieldType<FieldTypeConfig>['formControl'];
  showError = false;

  autoCompleteOptions: google.maps.places.AutocompleteOptions = {
    componentRestrictions: {
      country: 'id',
    },
    fields: ['formatted_address', 'geometry', 'place_id'],
  };
  markerPosition?: google.maps.LatLngLiteral;
  markerOptions: google.maps.MarkerOptions = {};

  /**
   * Where to pan map by default if no initial input.
   */
  mapPanDefault: LatLngLiteral = {
    lat: -6.877,
    lng: 107.618,
  };

  subscriptions: Subscription[] = [];

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    // create a new form control for the address field based on the address field of the
    // real form control
    const formControlOriginal = this.field.formControl;
    this.formControl = new UntypedFormControl(
      formControlOriginal.value?.address || '',
    );

    // copy errors from the original form control to here.
    this.subscriptions.push(
      formControlOriginal.statusChanges.subscribe((formControlStatus) => {
        (this.formControl as { status: FormControlStatus }).status =
          formControlStatus;
        this.showError = this.formControl.invalid;
        // no need to copy the error, since we pass the actual field to the validation
        // message ui
      }),
    );

    // copy value from the original form control to here.
    this.subscriptions.push(
      formControlOriginal.valueChanges.subscribe(
        (valueNew: AddressFieldModel) => {
          if (valueNew) {
            this.formControl.setValue(valueNew.address);
          }
          this.changeDetectorRef.detectChanges();
        },
      ),
    );

    // We need to initialize the map marker here because otherwise it won't be detected
    // by change detection.
    if (this.addressFieldModelInitial) {
      this.markerPosition = this.addressFieldModelInitial.latLngLiteral;
    }
  }

  ngAfterViewInit() {
    this.searchField.getInputElement().then((inputElement) => {
      // turn on autocomplete for the input element.
      const autoComplete = new google.maps.places.Autocomplete(
        inputElement,
        this.autoCompleteOptions,
      );

      // bias searchbox towards current map's viewport.
      this.subscriptions.push(
        this.map.boundsChanged.subscribe(() => {
          const bounds = this.map.getBounds();
          if (bounds) {
            autoComplete.setBounds(bounds);
          }
        }),
      );

      // react whenever the user change their location.
      autoComplete.addListener('place_changed', () => {
        const place = autoComplete.getPlace();
        if (!place.geometry || !place.geometry.location) {
          // user entered an unrecognized place
          this.placeSelected.emit(null);
        } else {
          this.placeSelected.emit(place);
          this.refreshPlace(place);
        }
      });

      if (this.addressFieldModelInitial) {
        this.refreshPlace({
          geometry: {
            location: new google.maps.LatLng(
              this.addressFieldModelInitial.latLngLiteral,
            ),
          },
        });
      } else {
        // otherwise, default to default location.
        this.map.panTo(this.mapPanDefault);
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Triggered when the input lose focus.
   */
  inputBlur() {
    if (this.formControl.value !== this.field.formControl.value?.address) {
      this.placeSelected.emit(null);
    }
  }

  private refreshPlace(place: google.maps.places.PlaceResult) {
    assert(place.geometry);
    assert(place.geometry.location);

    this.map.panTo(place.geometry.location);

    this.markerPosition = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };
  }

  getClass() {
    return this.field.formControl.value ? '' : 'google-map--hidden';
  }
}
