import { Component } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'ts-password-field',
  styleUrls: ['./password.field.component.scss'],
  templateUrl: './password.field.component.html',
  // can't use OnPush for form fields
})
export class PasswordFieldComponent extends FieldType<FieldTypeConfig> {
  /**
   * Whether the user has pressed the eye button and the password is shown to user.
   */
  isPasswordShown = false;
}
