import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { syncStringPromise } from '@ts/shared/util-async';
import { logger } from '@ts/shared/util-logging';
import { PlatformService } from '@ts/shared/util-platform';

export type ToastColor = 'primary' | 'info' | 'success' | 'warning' | 'danger';

export interface ToastShowParams {
  header?: string | Promise<string>;
  message: string | Promise<string>;
  /**
   * Duration in milliseconds on how long the toast should remain up.
   *
   * Put 0 if you want the toast to stay up indefinitely until the user close it.
   */
  duration?: number;
  color: ToastColor;
}

export type ToastShowNoColorParams = Omit<ToastShowParams, 'color'>;

/**
 * Wrapper for Ion ToastController, for displaying toasts.
 */
@Injectable({
  providedIn: 'root',
})
export class ToastService {
  /**
   * How long should the toast remains up before it fades away? In milliseconds.
   */
  toastDurationMs = 5000;

  constructor(
    private platformService: PlatformService,
    private toastController: ToastController,
  ) {}

  private async show$({
    header,
    message,
    color,
    duration = this.toastDurationMs,
  }: ToastShowParams): Promise<void> {
    const parsedMessage = await syncStringPromise(message);
    const parsedHeader = header ? await syncStringPromise(header) : undefined;

    this.logToast({ message: parsedMessage, header: parsedHeader, color });
    const toast = await this.toastController.create({
      header: parsedHeader,
      message: parsedMessage,
      duration: duration,
      color: color === 'info' ? undefined : color,
      position: this.platformService.isMobile() ? 'bottom' : 'top',
    });
    await toast.present();
  }

  private logToast({
    message,
    header,
    color,
  }: {
    message: string;
    header?: string;
    color: ToastColor;
  }) {
    logger.info(`toast-${color}: ${header ? `(${header}) ` : ''}${message}`);
  }

  primary$(params: ToastShowNoColorParams): Promise<void> {
    return this.show$({ ...params, color: 'primary' });
  }

  info$(params: ToastShowNoColorParams): Promise<void> {
    return this.show$({ ...params, color: 'info' });
  }

  success$(params: ToastShowNoColorParams): Promise<void> {
    return this.show$({ ...params, color: 'success' });
  }

  warning$(params: ToastShowNoColorParams): Promise<void> {
    return this.show$({ ...params, color: 'warning' });
  }

  danger$(params: ToastShowNoColorParams): Promise<void> {
    return this.show$({ ...params, color: 'danger' });
  }
}
