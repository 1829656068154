<!-- TODO(farid): Change locale value using i18nService -->
<ion-input
  [formControl]="dateTimeDisplayFormControl"
  [formlyAttributes]="field"
  (click)="popoverShow($event)"
  readonly
></ion-input>
<ion-popover size="auto" showBackdrop="true" #popover>
  <ng-template #popoverContent>
    <ion-datetime
      [formControl]="dateTimeFormControl"
      [formlyAttributes]="field"
      (ionChange)="setSelectedDate()"
      locale="id"
      [showClearButton]="true"
      [showDefaultButtons]="props.showDefaultButton"
      [presentation]="props.presentation"
      [value]="dateTimeFormControl.value"
    ></ion-datetime>
  </ng-template>
</ion-popover>
