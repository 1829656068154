import { Injectable } from '@angular/core';

import { strict as assert } from 'assert';

import { Currency, Money } from './money.model';

@Injectable({
  providedIn: 'root',
})
export class MoneyOperationService {
  /**
   * Converts the Money object to number, discarding their currencies.
   */
  moneyToNumber(money: Money): number {
    return Number(money.amount);
  }

  /**
   * Converts number to money with two decimal places.
   */
  numberToMoney(number: number, currency: Currency): Money {
    return {
      amount: number.toFixed(2),
      currency: currency,
    };
  }

  private verifyCompatible(first: Money, second: Money) {
    assert(first.currency === second.currency);
  }

  // DO NOT ADD MULTIPLICATION OR DIVISION
  // These computations are "destructive" should be done in the server instead.
  // Addition can be added though.

  /**
   * Returns "base" + "amountAdded"
   */
  moneyAdd(base: Money, amountAdded: Money): Money {
    this.verifyCompatible(base, amountAdded);
    return this.numberToMoney(
      this.moneyToNumber(base) + this.moneyToNumber(amountAdded),
      base.currency,
    );
  }

  /**
   * Returns "base" - "amountSubtracted"
   */
  moneySubtract(base: Money, amountSubtracted: Money): Money {
    this.verifyCompatible(base, amountSubtracted);
    return this.numberToMoney(
      this.moneyToNumber(base) - this.moneyToNumber(amountSubtracted),
      base.currency,
    );
  }

  /**
   * Returns true if money amount is zero, otherwise false.
   */
  isMoneyZero(base: Money): boolean {
    return !this.moneyToNumber(base);
  }

  /**
   * Returns true if money decimal amount is zero, otherwise false.
   */
  isMoneyDecimalZero(base: Money): boolean {
    return Number(base.amount) % 1 === 0;
  }

  /**
   * Creates a zero money with the same currency with another money.
   */
  makeZero(base: Money): Money {
    return {
      amount: '0.00',
      currency: base.currency,
    };
  }
}
