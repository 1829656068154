import { Injectable, Injector } from '@angular/core';
import {
  Analytics,
  EventParams,
  isSupported,
  logEvent,
  setUserId,
} from '@angular/fire/analytics';

import { EMPTY, from, Observable, of, switchMap } from 'rxjs';

import { User } from '@ts/user/shared/util-core';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsWrapperService {
  private isAnalyticsLoaded = false;
  private analytics?: Analytics;

  constructor(private injector: Injector) {}

  private getAnalytics$(): Observable<Analytics> {
    if (this.isAnalyticsLoaded) {
      if (this.analytics) {
        return of(this.analytics);
      } else {
        return EMPTY;
      }
    } else {
      return from(isSupported()).pipe(
        switchMap(() => {
          this.isAnalyticsLoaded = true;
          this.analytics = this.injector.get(Analytics);
          return this.getAnalytics$();
        }),
      );
    }
  }

  setUser(user: User) {
    this.getAnalytics$().subscribe((analytics) => {
      setUserId(analytics, user.id.toString());
    });
  }

  logEvent(
    eventName: Parameters<typeof logEvent>[1],
    eventParams?: EventParams,
    options?: Parameters<typeof logEvent>[3],
  ) {
    this.getAnalytics$().subscribe((analytics) => {
      logEvent(analytics, eventName, eventParams, options);
    });
  }
}
