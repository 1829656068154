import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Translation, TranslocoLoader } from '@ngneat/transloco';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(language: string) {
    return this.http.get<Translation>(`/assets/i18n/${language}.json`);
  }
}
