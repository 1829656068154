import { take } from 'rxjs';

import { AnalyticsWrapperService } from '@ts/analytics/shared/data-access';
import { AuthenticationService } from '@ts/shared/authentication/data-access';

import { UserStreamService } from './user-stream.service';

/**
 * Subscribes permanently to login to refresh user information whenever user logs in or logs out.
 */
export function initUserService(
  authenticationService: AuthenticationService,
  userStreamService: UserStreamService,
  analyticsWrapperService: AnalyticsWrapperService,
): () => void {
  return () => {
    authenticationService.isLoggedIn$.subscribe((isLoggedIn) => {
      userStreamService.refresh();
      if (isLoggedIn) {
        userStreamService.truthyEntity$.pipe(take(1)).subscribe((user) => {
          analyticsWrapperService.setUser(user);
        });
      }
    });
  };
}
