import { concat, map, Observable, of, switchMap } from 'rxjs';

import { ApiConsumerService } from '@ts/shared/api/data-access-api-consumer';
import { EntityStreamAbstractService } from '@ts/shared/data/data-access-entity-stream';

/**
 * Abstracts retrieving an entity from an API.
 *
 * @example
 *
 * ```
 * @Injectable({
 *   providedIn: 'root',
 * })
 * export class BasketStreamService extends ApiEntityStreamAbstractService<Basket> {
 *   override relativeUrl$ = of('some/url');
 *
 *   constructor(
 *     protected override apiService: BormaDagoAuthenticatedApiConsumerService
 *   ) {
 *     super();
 *   }
 * }
 * ```
 */
export abstract class ApiEntityStreamAbstractService<
  T,
> extends EntityStreamAbstractService<T> {
  abstract relativeUrl$: Observable<string>;
  protected abstract apiService: ApiConsumerService;

  /**
   * Get the query params associated with the url request, if any.
   */
  protected getRequestQueryParams$(): Observable<Record<string, string>> {
    return of({});
  }

  protected getApiServiceParams$(
    relativeUrl: string,
  ): Observable<Parameters<ApiConsumerService['get$']>> {
    return this.getRequestQueryParams$().pipe(
      map((queryParams) => {
        return [
          {
            relativeUrl,
            queryParams,
          },
        ];
      }),
    );
  }

  fetchFromApiService$(relativeUrl: string): Observable<T> {
    return this.getApiServiceParams$(relativeUrl).pipe(
      switchMap((params) => this.apiService.get$<T>(...params)),
    );
  }

  fetchUrl$(): Observable<T | null> {
    return this.relativeUrl$.pipe(
      switchMap((relativeUrl) =>
        concat(of(null), this.fetchFromApiService$(relativeUrl)),
      ),
    );
  }

  override getRefreshedEntity$(): Observable<T | null> {
    // we separate this into its own method to reuse in the paginated version of this service.
    return this.fetchUrl$();
  }
}
