export type Currency = 'IDR';
export type Sign = '-' | '';

export const CURRENCY_DEFAULT: Currency = 'IDR';

interface MoneyBase {
  currency: Currency;
  /**
   * String representation of the number, optionally with decimals (separator is '.')
   *
   * @example
   * `10000.00`
   * `0.00`
   * `0`
   * `-10000.00`
   */
  amount: string;
}

export type Money = Readonly<MoneyBase>;

interface MoneyDisplayBase {
  sign: Sign;
  currency: string;
  amount: string;
  decimal: string;
  decimalSeparator: string;
}

export type MoneyDisplay = Readonly<MoneyDisplayBase>;
