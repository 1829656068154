import { of } from 'rxjs';

import { Injectable } from '@angular/core';
import { BormaDagoPersistentAuthenticatedApiConsumerService } from '@ts/shared/api/data-access-borma-dago-authenticated-api-consumer';
import { ApiEntityStreamPersistentAbstractService } from '@ts/shared/data/data-access-api-entity-stream';
import { User } from '@ts/user/shared/util-core';

/**
 * Retrieves the logged-in user's current information.
 *
 * Persistent.
 */
@Injectable({
  providedIn: 'root',
})
export class UserStreamService extends ApiEntityStreamPersistentAbstractService<User> {
  override relativeUrl$ = of('api/user/self/get/');

  constructor(
    protected override apiService: BormaDagoPersistentAuthenticatedApiConsumerService,
  ) {
    super();
  }
}
