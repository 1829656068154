import { I18nService } from '@ts/shared/18n/util-core';
import { UserStreamService } from '@ts/user/shared/data-access';

/**
 * Subscribes to user service to refresh language whenever the user changes.
 */
export function initI18nService(
  userStreamService: UserStreamService,
  i18nService: I18nService,
  document: Document,
): () => void {
  return () => {
    i18nService.loadLanguageFromCookie();

    // If the user changes, update the language based on the user's saved language.
    userStreamService.truthyEntity$.subscribe((user) => {
      const previousLanguage = i18nService.getLanguageActive();
      if (user.language !== previousLanguage) {
        i18nService.setLanguageActive(user.language);
        // changing language should refresh the page
        window.location.reload();
      }
    });

    // Set the document HTML language.
    document.documentElement.lang = i18nService.getLanguageActive();
  };
}
