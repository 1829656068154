import { AbstractControl, ValidationErrors } from '@angular/forms';

export function otpValidator(
  control: AbstractControl,
): ValidationErrors | null {
  const value: string | undefined = control.value;
  if (!value) {
    // empty value is ok, because this will be caught by the "required" validator
    return null;
  }
  if (!value.match(/^\d*$/)) {
    return {
      otp: true,
    };
  }

  return null;
}
