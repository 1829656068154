import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { EMPTY, from, Observable, of, switchMap } from 'rxjs';

import { AlertController } from '@ionic/angular';
import { I18nService } from '@ts/shared/18n/util-core';
import { syncStringPromise } from '@ts/shared/util-async';
import { logger } from '@ts/shared/util-logging';

import { AlertType } from './alert.model';

export interface AlertServiceStandardParams {
  message: string | Promise<string>;
  /**
   * If blank, will be replaced with a default error header message.
   */
  header?: string | Promise<string>;
}
export interface AlertServiceConfirmParams {
  /**
   * Translated action sentence fragment.
   *
   * @example
   *
   * `delete user address xyz`.
   *
   * The sentence will then become:
   *
   * "Are you sure you want to delete user address xyz?"
   */
  action: string | Promise<string>;

  type: AlertType;

  /**
   * If true, then a warning under will be shown that says something like:
   * "This action cannot be undone."
   */
  isShowPermanentWarning?: boolean;

  /**
   * If true, then a warning under will shown action message only without prefix or suffix sentence
   */
  isShowActionMessageOnly?: boolean;
}

// exported to be used in tests.
export const ROLE_CANCEL = 'cancel';
export const ROLE_CONFIRM = 'confirm';

/**
 * Wrapper for Ion AlertController, for showing various kinds of alerts to the user.
 */
@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(
    private alertController: AlertController,
    private i18nService: I18nService,
    private domSanitizer: DomSanitizer,
  ) {}

  // Css class for alert button
  primaryButtonCssClass = 'button-primary';
  secondaryButtonCssClass = 'button-secondary';
  dangerButtonCssClass = 'button-danger';

  private getCssClass(type: AlertType): string {
    return `alert-wide alert-${type}`;
  }

  private async createAlert$(
    type: AlertType,
    { message, header }: AlertServiceStandardParams,
  ): Promise<string> {
    let headerContent: string;
    if (!header) {
      headerContent = await this.i18nService.translate$(
        `sharedUtilAlert.${type}.title`,
      );
    } else {
      headerContent = await syncStringPromise(header);
    }

    const messageContent = await syncStringPromise(message);
    const alert = await this.alertController.create({
      message: messageContent,
      header: headerContent,
      cssClass: this.getCssClass(type),
      buttons: ['ok'],
    });

    await alert.present();

    return messageContent;
  }

  /**
   * Shows an error and prompt it to the user.
   */
  async error$(alertServiceStandardParams: AlertServiceStandardParams) {
    // For error, we want to attach a self help link at the end.
    const message = `
      <div>
        ${this.domSanitizer.sanitize(
          SecurityContext.HTML,
          await syncStringPromise(alertServiceStandardParams.message),
        )}
      </div>
      <br/>
      <a href="/shop/faq/contact-us">
        ${await this.i18nService.translate$('shop.footer.contactUs')}
      </a>
    `;

    await this.createAlert$('danger', {
      ...alertServiceStandardParams,
      message,
    });
    logger.error(await syncStringPromise(alertServiceStandardParams.message));
  }

  async success$(alertServiceStandardParams: AlertServiceStandardParams) {
    const message = await this.createAlert$(
      'success',
      alertServiceStandardParams,
    );
    logger.info(message);
  }

  /**
   * Shows a confirm dialog to the user.
   *
   * The returned observable will emit once if the user confirms.
   * Otherwise, it'll just close without emitting.
   *
   * No need to manage subscription because ionic will kill the subscription when the
   * alert is closed.
   *
   * @example
   *
   * ```
   * alertService.confirm$({action: i18nService.translate$('address.delete')}).subscribe(() => {
   *   actionService.deleteAddress(...)
   * })
   * ```
   */
  confirm$(
    alertServiceConfirmParams: AlertServiceConfirmParams,
  ): Observable<void> {
    return from(this.confirmDialogShow(alertServiceConfirmParams)).pipe(
      switchMap((alert) => {
        return from(alert.onDidDismiss());
      }),
      switchMap((dismissResult): Observable<void> => {
        if (dismissResult.role === ROLE_CONFIRM) {
          return of(undefined);
        } else {
          return EMPTY;
        }
      }),
    );
  }

  private async confirmDialogShow({
    action,
    isShowPermanentWarning,
    type,
    isShowActionMessageOnly,
  }: AlertServiceConfirmParams): Promise<HTMLIonAlertElement> {
    const actionContent = await syncStringPromise(action);

    const headerContent = isShowActionMessageOnly
      ? actionContent
      : await this.i18nService.translate$('sharedUtilAlert.confirm.message', {
          action: actionContent,
        });

    const messageContent = isShowPermanentWarning
      ? await this.i18nService.translate$(
          'sharedUtilAlert.confirm.permanentWarning',
        )
      : undefined;

    const alert = await this.alertController.create({
      header: headerContent,
      message: messageContent,
      cssClass: this.getCssClass(type),
      buttons: [
        {
          text: await this.i18nService.translate$(
            'sharedUtilAlert.confirm.cancel',
          ),
          role: ROLE_CANCEL,
          cssClass: isShowPermanentWarning
            ? this.primaryButtonCssClass
            : this.secondaryButtonCssClass,
        },
        {
          text: await this.i18nService.translate$(
            'sharedUtilAlert.confirm.confirm',
          ),
          role: ROLE_CONFIRM,
          cssClass: isShowPermanentWarning
            ? this.dangerButtonCssClass
            : this.primaryButtonCssClass,
        },
      ],
    });

    await alert.present();

    return alert;
  }
}
