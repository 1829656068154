import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { EnvironmentService } from '@ts/shared/util-environment';
import { ScriptLoaderService } from '@ts/shared/util-script';

/**
 * For lazy loading google map API.
 */
@Injectable({
  providedIn: 'root',
})
export class GoogleMapsScriptLoaderService {
  apiUrl = 'https://maps.googleapis.com/maps/api/js?key=%s&libraries=places';

  isScriptLoaded$: Observable<boolean>;

  constructor(
    scriptLoaderService: ScriptLoaderService,
    environmentService: EnvironmentService,
  ) {
    const apiKey = environmentService.get().googleMapsApiKey;
    this.isScriptLoaded$ = scriptLoaderService.loadAndPersistJsonp$(
      sprintf(this.apiUrl, apiKey),
    );
  }
}
