import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyIonicModule } from '@ngx-formly/ionic';

import { OtpFormFieldUiComponent } from './otp-form-field-ui/otp-form-field-ui.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    FormlyIonicModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'otp',
          component: OtpFormFieldUiComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
  ],
  declarations: [OtpFormFieldUiComponent],
  exports: [OtpFormFieldUiComponent],
})
export class OtpSharedFormUiFieldModule {}
