import { ErrorHandler, Provider } from '@angular/core';

import { createErrorHandler, init as sentryAngularInit } from '@sentry/angular';
import { init as sentryCapacitorInit } from '@sentry/capacitor';
import { environment } from '@ts/shared/util-environment';

export function initializeSentryProvider(): readonly Provider[] {
  const additionalProviders: Provider[] = [];

  if (environment.sentryDsn) {
    // Init by passing the sibling SDK's init as the second parameter.
    sentryCapacitorInit(
      {
        dsn: environment.sentryDsn,
        tracesSampleRate: environment.sentryTracesSampleRate,
      },
      sentryAngularInit,
    );
    additionalProviders.push({
      provide: ErrorHandler,
      useValue: createErrorHandler(),
    });
  }
  return additionalProviders;
}
