import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { ApiConsumerCallParams } from '@ts/shared/api/data-access-api-consumer';

import { BormaDagoAuthenticatedApiConsumerService } from './borma-dago-api-authenticated-consumer.service';

@Injectable({
  providedIn: 'root',
})
export class BormaDagoAuthenticatedApiConsumerWithCredentialsService extends BormaDagoAuthenticatedApiConsumerService {
  protected override getCallParams$(
    params: ApiConsumerCallParams,
  ): Observable<ApiConsumerCallParams> {
    return super.getCallParams$(params).pipe(
      map((callParams) => {
        return {
          ...callParams,
          httpOptions: {
            ...callParams.httpOptions,
            withCredentials: true,
          },
        };
      }),
    );
  }
}
