/**
 * Returns a Promise<string> from a string or a Promise<string>.
 *
 * Note we can't really make this generic because it's hard to tell if the object isn't a string.
 */
export async function syncStringPromise(
  rawString: string | Promise<string>,
): Promise<string> {
  if (typeof rawString === 'string') {
    return rawString;
  } else {
    return await rawString;
  }
}
