import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, Type } from '@angular/core';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { BrowserModule } from '@angular/platform-browser';

import { strict as assert } from 'assert';

import { IonicModule } from '@ionic/angular';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { OtpSharedFormUiFieldModule } from '@ts/otp/shared/form/ui-field';
import { RecaptchaSharedUtilCoreModule } from '@ts/recaptcha/shared/util-core';
import { SharedAuthenticationDataAccessModule } from '@ts/shared/authentication/data-access';
import { SharedFormUiFormlyModule } from '@ts/shared/form/ui-formly';
import { SharedI18nDataAccessModule } from '@ts/shared/i18n/data-access';
import { I18nRootShellModule } from '@ts/shared/i18n/util-app-shell';
import { environment } from '@ts/shared/util-environment';
import { UserSharedDataAccessModule } from '@ts/user/shared/data-access';

export function getAppImports(): Array<
  ModuleWithProviders<{}> | Type<unknown>
> {
  return [
    BrowserModule,
    IonicModule.forRoot({
      // https://forum.ionicframework.com/t/ionic-4-keyboard-scroll-view-when-ion-input-is-focused/160502/10
      // without this, keyboard may cover input fields.
      // This is probably because scrollAssist override the focus event, which usually triggers
      // the browser to scroll up/down.
      // Ditto for the padding (scrollPadding)
      scrollAssist: false,
      scrollPadding: false,
    }),
    HttpClientModule,
    HttpClientJsonpModule,
    I18nRootShellModule,
    StoreModule.forRoot({
      router: routerReducer,
    }),
    // Connects RouterModule with StoreModule, uses MinimalRouterStateSerializer by default
    StoreRouterConnectingModule.forRoot(),
    // Instrumentation must be imported after importing StoreModule (config is optional)
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    // ==================
    //   SHARED MODULES
    // ==================
    SharedAuthenticationDataAccessModule.forRoot(),
    SharedFormUiFormlyModule.forRoot(),
    UserSharedDataAccessModule.forRoot(),
    SharedI18nDataAccessModule.forRoot(),
    RecaptchaSharedUtilCoreModule.forRoot(),
    // TODO(irvan): lazy load this module:
    OtpSharedFormUiFieldModule,
    ...(environment.firebaseConfig
      ? [
          provideAnalytics(() => getAnalytics()),
          provideFirebaseApp(() => {
            assert(environment.firebaseConfig);
            return initializeApp(environment.firebaseConfig);
          }),
        ]
      : []),
  ];
}
