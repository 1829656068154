import { Injectable } from '@angular/core';

import { strict as assert } from 'assert';

import { LOCALE } from './money.constant';
import { Currency, Money, MoneyDisplay, Sign } from './money.model';

@Injectable({
  providedIn: 'root',
})
export class MoneyFormatService {
  CURRENCY_TO_DISPLAY: { [currency in Currency]: string } = {
    IDR: 'Rp',
  };

  getMoneyDisplay(money: Money): MoneyDisplay {
    const splitted = money.amount.split('.');
    assert([1, 2].includes(splitted.length));

    let integerPart = Number(splitted[0]);
    let sign: Sign;
    if (integerPart < 0) {
      sign = '-';
      integerPart = -integerPart;
    } else {
      sign = '';
    }

    return {
      sign,
      currency: this.CURRENCY_TO_DISPLAY[money.currency],
      amount: new Intl.NumberFormat(LOCALE).format(integerPart),
      decimal: splitted.length === 2 ? splitted[1] : '00',
      decimalSeparator: ',',
    };
  }

  /**
   * @example
   * ```
   * formatMoney({currency: 'IDR', amount: '-15000.00'}) == 'Rp -15.000,00'
   * ```
   */
  formatMoney(money: Money): string {
    const display = this.getMoneyDisplay(money);
    return `${display.sign}${display.currency}\xa0${display.amount}${display.decimalSeparator}${display.decimal}`;
  }
}
