import { Environment } from './environment.model';

// staging is actually "test" server right now.
export const environment: Environment = {
  production: true,
  logLevel: 'debug',

  app: 'solui',

  // we still use dev server here for staging until we get a proper
  // separation between "test" server and a "staging" server.
  bormaDagoApiUrl: 'https://dev.bormadago.com',

  soluiUrl: 'https://staging.bormadago.com',

  sentryDsn:
    'https://371c361addb740b4bf1abbe3e84c20a7@o511135.ingest.sentry.io/6115891',
  sentryTracesSampleRate: 0.1,

  googleMapsApiKey: 'AIzaSyBsNyyLb3Q1undKp1pCoNlO_RrwDogQga8',

  googlePlayUrl:
    'https://play.google.com/store/apps/details?id=com.bormadago.app',

  appStoreUrl: '',

  recaptchaSiteKey: '6LcxZPQiAAAAADvcoS_iMcOXqJC9FWDBQoRwZBW_',

  firebaseConfig: {
    apiKey: 'AIzaSyDr6rbjqvlrWBlS0hUtgfU3hSsL5gzdDMM',
    appId: '1:200557227594:web:dec93a71b773909b0540b3',
    projectId: 'solui-master',
    measurementId: 'G-3HCCXEGNG8',
  },

  dokuJokulUrl:
    'https://sandbox.doku.com/jokul-checkout-js/v1/jokul-checkout-1.0.0.js',
};
