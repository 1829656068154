import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable, take, tap } from 'rxjs';

import { NavController } from '@ionic/angular';
import { AuthenticationService } from '@ts/shared/authentication/data-access';

/**
 * Require user to login when access certain url/route
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard {
  constructor(
    protected authenticationService: AuthenticationService,
    protected navController: NavController,
  ) {}

  protected navigateLogin(state: RouterStateSnapshot) {
    this.navController.navigateForward(['/shop/login'], {
      queryParams: {
        nextUrl: state.url,
      },
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.authenticationService.isLoggedIn$.pipe(
      take(1),
      tap((isLoggedIn) => {
        if (!isLoggedIn) {
          this.navigateLogin(state);
        }
      }),
    );
  }
}
