import { Injectable } from '@angular/core';

import { from } from 'rxjs';

import { FormlyFieldConfig } from '@ngx-formly/core';
import { ORDER_STATUSES, OrderStatus } from '@ts/order/shared/util-core';
import { OrderStatusI18nService } from '@ts/order/shared/util-status-i18n';

interface OrderStatusOption {
  text: string;
  status: OrderStatus;
}

@Injectable()
export class OrderStatusPresetProvider {
  constructor(private orderStatusI18nService: OrderStatusI18nService) {}

  getConfiguration(): FormlyFieldConfig {
    return {
      type: 'select',
      props: {
        i18n: true,
        label: 'filter.orderStatus',
        options: from(this.getOrderStatusTypeOptions()),
        labelProp: 'text',
        valueProp: 'status',
        interface: 'popover',
        labelPosition: 'floating',
      },
    };
  }

  private async getOrderStatusTypeOptions(): Promise<OrderStatusOption[]> {
    const orderStatusOptionsI18n: OrderStatusOption[] = [];

    for (const orderStatus of ORDER_STATUSES) {
      orderStatusOptionsI18n.push({
        text: await this.orderStatusI18nService.translate$(orderStatus),
        status: orderStatus,
      });
    }

    return orderStatusOptionsI18n;
  }
}
