import { Component, OnInit } from '@angular/core';

import { strict as assert } from 'assert';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

import { otpValidator } from '../otp.validator';

@Component({
  selector: 'ts-otp-form-field-ui',
  templateUrl: './otp-form-field-ui.component.html',
  styleUrls: ['./otp-form-field-ui.component.scss'],
  // can't use on push for form fields
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtpFormFieldUiComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  OTP_LENGTH = 6;

  ngOnInit() {
    // TODO(irvan): These are hacks, the props are supposed to be readonly.
    assert(this.props);
    this.props['maxLength'] = this.OTP_LENGTH;
    this.props['minLength'] = this.OTP_LENGTH;

    this.formControl.addValidators(otpValidator);
  }
}
