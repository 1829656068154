import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { strict as assert } from 'assert';
import {
  catchError,
  filter,
  map,
  Observable,
  switchMap,
  take,
  throwError,
} from 'rxjs';

import {
  ApiConsumerCallParams,
  ApiConsumerCallParamsWithBody,
  ApiConsumerCallParamsWithoutBody,
  ApiConsumerService,
} from '@ts/shared/api/data-access-api-consumer';
import { BormaDagoApiConsumerService } from '@ts/shared/api/data-access-borma-dago-api-consumer';
import { BormaDagoHttpErrorResponse } from '@ts/shared/api/util-borma-dago';
import { AuthenticationService } from '@ts/shared/authentication/data-access';

/**
 * Calls Borma Dago API with authentication information attached.
 *
 * If the user is not authenticated, the request will be dropped and not retried.
 */
@Injectable({
  providedIn: 'root',
})
export class BormaDagoAuthenticatedApiConsumerService
  implements ApiConsumerService
{
  constructor(
    private bormaDagoApiConsumerService: BormaDagoApiConsumerService,
    protected authenticationService: AuthenticationService,
  ) {}

  protected getAuthenticationState$(): AuthenticationService['authenticationState$'] {
    return this.authenticationService.authenticationState$.pipe(take(1));
  }

  protected getCallParams$(
    params: ApiConsumerCallParams,
  ): Observable<ApiConsumerCallParams> {
    return this.getAuthenticationState$().pipe(
      // if user hasn't logged in, do nothing
      filter((state) => state !== null),
      take(1),
      map((state) => {
        // append Authorization: Bearer to headers.
        const existingHeaders =
          params.httpOptions?.headers || new HttpHeaders();

        assert(state !== null);

        return {
          ...params,
          httpOptions: {
            ...params.httpOptions,
            headers: existingHeaders.append(
              'Authorization',
              `Bearer ${state.access}`,
            ),
          },
        };
      }),
    );
  }

  private call$<T>(baseParams: ApiConsumerCallParams): Observable<T> {
    // Fetch the authentication state
    return this.getCallParams$(baseParams).pipe(
      // Use it to try and call the API, and handle the case when the token is expired
      switchMap((params) => {
        return this.bormaDagoApiConsumerService.call$<T>({
          ...params,
          errorCodesToHandleManually: (
            params.errorCodesToHandleManually || []
          ).concat(this.authenticationService.TOKEN_NOT_VALID_ERROR_CODE),
        });
      }),
      // On failure, if it's due to token expired:
      // renew token until it succeed and then try ONE more time
      catchError((error: BormaDagoHttpErrorResponse) => {
        if (
          error.error.error_code ===
          this.authenticationService.TOKEN_NOT_VALID_ERROR_CODE
        ) {
          // refresh token, then get the new params, then try to call the api again
          return this.authenticationService.renewJwtToken$().pipe(
            switchMap(() => this.getCallParams$(baseParams)),
            switchMap((params) =>
              this.bormaDagoApiConsumerService.call$<T>(params),
            ),
          );
        } else {
          return throwError(() => error);
        }
      }),
    );
  }

  get$<T>(params: ApiConsumerCallParamsWithoutBody): Observable<T> {
    return this.call$<T>({ ...params, method: 'get' });
  }
  delete$<T>(params: ApiConsumerCallParamsWithoutBody): Observable<T> {
    return this.call$<T>({ ...params, method: 'delete' });
  }
  post$<T>(params: ApiConsumerCallParamsWithBody): Observable<T> {
    return this.call$<T>({ ...params, method: 'post' });
  }
  put$<T>(params: ApiConsumerCallParamsWithBody): Observable<T> {
    return this.call$<T>({ ...params, method: 'put' });
  }
  patch$<T>(params: ApiConsumerCallParamsWithBody): Observable<T> {
    return this.call$<T>({ ...params, method: 'patch' });
  }
}
