import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  ValidationErrors,
} from '@angular/forms';

import { PhoneNumberUtil } from 'google-libphonenumber';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'ts-phone-number-field',
  styleUrls: ['./phone-number.field.component.scss'],
  templateUrl: './phone-number.field.component.html',
  // can't use OnPush for form fields
})
export class PhoneNumberFieldComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  inputFormControl!: UntypedFormControl;

  phoneUtil = PhoneNumberUtil.getInstance();
  phoneNumberRegion = 'ID';
  placeholder = '081xxxxxxxx';

  ngOnInit() {
    this.formControl.addValidators([
      (control: AbstractControl): ValidationErrors | null => {
        try {
          const phoneNumber = this.phoneUtil.parse(
            control.value,
            this.phoneNumberRegion,
          );
          if (
            !this.phoneUtil.isValidNumberForRegion(
              phoneNumber,
              this.phoneNumberRegion,
            )
          ) {
            return {
              invalidPhoneNumber: { value: control.value },
            };
          } else {
            return null;
          }
        } catch (e) {
          return {
            invalidPhoneNumber: { value: control.value },
          };
        }
      },
    ]);
  }
}
