import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { strict as assert } from 'assert';

import { FormlyTemplateOptions } from '@ngx-formly/core';

@Component({
  selector: 'ts-ion-form-field-help-ui',
  templateUrl: './ion-form-field-help-ui.component.html',
  styleUrls: ['./ion-form-field-help-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IonFormFieldHelpUiComponent implements AfterViewInit {
  @Input() props!: FormlyTemplateOptions;

  @ViewChild('helpTextContainer', { read: ViewContainerRef })
  helpTextViewContainerRef?: ViewContainerRef;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    if (this.props['helpComponent']) {
      assert(this.helpTextViewContainerRef);
      this.helpTextViewContainerRef.createComponent(
        this.props['helpComponent'],
      );
      // not sure why needed, but without this the link won't show.
      this.changeDetectorRef.detectChanges();
    }
  }
}
