import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  catchError,
  EMPTY,
  filter,
  interval,
  map,
  Observable,
  shareReplay,
  switchMap,
  take,
} from 'rxjs';

import { logger } from '@ts/shared/util-logging';

export type ScriptAttributesNames = 'data-client-key';

export interface ScriptAttribute {
  name: ScriptAttributesNames;
  value: string;
}

export interface ScriptLoaderParams {
  url: string;
  scriptAttribute?: ScriptAttribute;
}

/**
 * For loading external scripts.
 */
@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  waitEveryMs = 300;

  constructor(private httpClient: HttpClient) {}

  /**
   * Loads a script and persist it using jsonp
   */
  loadAndPersistJsonp$(url: string): Observable<boolean> {
    return this.httpClient.jsonp(url, 'callback').pipe(
      map(() => true),
      catchError((error) => {
        logger.error(`Unable to load url '${url}' because: ${error}`);
        return EMPTY;
      }),
      take(1),
      shareReplay(1),
    );
  }

  loadAndPersist$<T>(
    windowVariableName: string,
    scriptLoaderParams: ScriptLoaderParams,
  ): Observable<T> {
    return new Observable<void>((subscriber) => {
      this.loadManual(scriptLoaderParams);
      subscriber.next(undefined);
      subscriber.complete();
    }).pipe(
      take(1),
      // Emit windowVariableName every (this.waitEveryMs) milliseconds
      switchMap(() => interval(this.waitEveryMs)),
      map(() => (window as unknown as Record<string, T>)[windowVariableName]),
      // Wait until the windowVariableName is no longer undefined
      filter((variable) => !!variable),
      take(1),
      shareReplay(1),
    );
  }

  /**
   * Loads a script. Note that this doesn't persist it, you should persist it elsewhere.
   */
  loadManual(param: ScriptLoaderParams) {
    const script = document.createElement('script');
    script.innerHTML = '';

    script.src = param.url;
    script.async = true;
    script.defer = true;

    param.scriptAttribute &&
      script.setAttribute(
        param.scriptAttribute.name,
        param.scriptAttribute.value,
      );

    document.head.appendChild(script);
  }
}
