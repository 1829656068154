import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { BormaDagoApiConsumerService } from '@ts/shared/api/data-access-borma-dago-api-consumer';
import { ApiEntityStreamPersistentAbstractService } from '@ts/shared/data/data-access-api-entity-stream';

import { CsrfResponse } from './csrf.model';

/**
 * Retrieves CSRF token.
 *
 * Persistent.
 */
@Injectable({
  providedIn: 'root',
})
export class BormaDagoCsrfStreamService extends ApiEntityStreamPersistentAbstractService<CsrfResponse> {
  override relativeUrl$ = of('api/csrf/set/');

  override fetchFromApiService$(relativeUrl: string): Observable<CsrfResponse> {
    return this.apiService.post$<CsrfResponse>({
      relativeUrl,
      body: {},
      httpOptions: {
        withCredentials: true,
      },
    });
  }

  constructor(protected override apiService: BormaDagoApiConsumerService) {
    super();
  }
}
