import { Component, Input } from '@angular/core';

import { FormlyFieldConfig } from '@ngx-formly/core';

// This is tested together in `ion-form-field-wrapper-ui` because
// some of the formly components used in the test is not exported by formly.
// Not our fault.
@Component({
  selector: 'ts-ion-form-field-validation-message-ui',
  templateUrl: './ion-form-field-validation-message-ui.component.html',
  styleUrls: ['./ion-form-field-validation-message-ui.component.scss'],
  // can't use onpush because the error content can change.
})
export class IonFormFieldValidationMessageUiComponent {
  @Input() showError!: boolean;
  @Input() field!: FormlyFieldConfig;
}
