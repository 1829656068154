import { Provider } from '@angular/core';
import { ScreenTrackingService } from '@angular/fire/analytics';
import { RouteReuseStrategy, TitleStrategy } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';

import { IonicRouteStrategy } from '@ionic/angular';
import { environment } from '@ts/shared/util-environment';
import { BormadagoTitleStrategy } from '@ts/shared/util-title';

import { initializeSentryProvider } from './sentry.util';

export function getAppProviders(): readonly Provider[] {
  return [
    CookieService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: TitleStrategy, useClass: BormadagoTitleStrategy },
    ...initializeSentryProvider(),
    ...(environment.firebaseConfig ? [ScreenTrackingService] : []),
  ];
}
