import {
  BehaviorSubject,
  filter,
  Observable,
  shareReplay,
  switchMap,
} from 'rxjs';

import { EntityStreamService } from './entity-stream.model';

/**
 * Abstracts retrieving entities, caching them, and returning them as an observable.
 *
 * The entities are cached so that multiple subscriptions to entity$ will not
 * repeatedly call `getRefreshedEntity$`.
 */
export abstract class EntityStreamAbstractService<T>
  implements EntityStreamService<T>
{
  /**
   * Emits each time the content of this stream needs to be hard refreshed.
   * You can subscribe to this if you want to get notified each time this stream changes.
   */
  refresh$ = new BehaviorSubject<null>(null);

  protected baseEntity$: Observable<T | null> = this.refresh$.pipe(
    switchMap(() => this.getRefreshedEntity$()),
  );

  /**
   * Note that by default this entity$ is only cached as long as it has at least one
   * subscriber.
   *
   * If you want to make sure the entity$ is always cached, override getEntity$:
   * ```
   * protected override getEntity$() {
   *   return this.baseEntity$.pipe(shareReplay(1));
   * }
   * ```
   */
  entity$: Observable<T | null>;

  truthyEntity$: Observable<T>;

  /**
   * Invalidates cache, if any, and refreshes to the latest entity by calling `getRefreshedEntity$`
   */
  refresh(): void {
    this.refresh$.next(null);
  }

  protected getEntity$(): Observable<T | null> {
    return this.baseEntity$.pipe(
      // See https://blog.strongbrew.io/share-replay-issue/
      shareReplay({
        bufferSize: 1,
        refCount: true,
      }),
    );
  }

  constructor() {
    this.entity$ = this.getEntity$();
    this.truthyEntity$ = this.entity$.pipe(
      filter((entity: T | null): entity is T => !!entity),
    );
  }

  /**
   * Returns the latest entity
   */
  abstract getRefreshedEntity$(): Observable<T | null>;
}
