<ion-item [lines]="to['itemLines']">
  <ts-ion-form-field-label-ui
    [to]="to"
    *ngIf="to.label"
  ></ts-ion-form-field-label-ui>
  <ng-template #fieldComponent></ng-template>
</ion-item>
<ts-ion-form-field-validation-message-ui
  [field]="field"
  [showError]="showError"
></ts-ion-form-field-validation-message-ui>
<ts-ion-form-field-help-ui [props]="to"></ts-ion-form-field-help-ui>
