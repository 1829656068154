import { Injectable } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, catchError, EMPTY, map, Observable, tap } from 'rxjs';

import { NavController } from '@ionic/angular';
import { I18nService } from '@ts/shared/18n/util-core';
import { BormaDagoCsrfApiConsumerService } from '@ts/shared/api/data-access-borma-dago-csrf';
import { BormaDagoErrorCode } from '@ts/shared/api/util-borma-dago';
import { AuthenticationJwtState } from '@ts/shared/authentication/util-core';
import { logger } from '@ts/shared/util-logging';
import { ToastService } from '@ts/shared/util-toast';

export interface LoginCredentials {
  identifier: string;
  password: string;
}

export const COOKIE_IS_AUTHENTICATED = 'AUTHENTICATION_TOKEN_EXISTS';

/**
 * Maintains and modifies user authentication state.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  authenticationState$: Observable<AuthenticationJwtState | null> =
    new BehaviorSubject(null);
  isLoggedIn$: Observable<boolean> = this.authenticationState$.pipe(
    map((state) => !!state),
  );

  JWT_ERROR_CODES_TO_HANDLE_MANUALLY: readonly BormaDagoErrorCode[] = [
    'permission_denied',
  ];
  TOKEN_NOT_VALID_ERROR_CODE: BormaDagoErrorCode = 'token_not_valid';

  jwtTokenUrl = 'api/jwt/token/';
  logoutUrl = 'api/user/logout/logout/';

  isAuthenticatedCookiesValidityDays = 7;

  constructor(
    private bormaDagoCsrfApiConsumerService: BormaDagoCsrfApiConsumerService,
    private cookieService: CookieService,
    private toastService: ToastService,
    private i18nService: I18nService,
    private navController: NavController,
  ) {}

  setJwtState(authenticationJwtState: AuthenticationJwtState) {
    (
      this
        .authenticationState$ as BehaviorSubject<AuthenticationJwtState | null>
    ).next(authenticationJwtState);
    // login validity is renewed each time jwt state is updated
    this.cookieService.set(
      COOKIE_IS_AUTHENTICATED,
      'true',
      /* expires (days) = */ this.isAuthenticatedCookiesValidityDays,
      /* path =*/ '/',
      /* domain = */ undefined,
      /* secure = */ true,
      /* sameSite = */ 'None',
    );
    logger.info('JWT state updated successfully');
  }

  /**
   * Logs out the user. Since we're using JWT, this just removes the JWT token
   * from the client without doing anything on the server.
   */
  logout() {
    // send an asynchronous request to log out
    this.bormaDagoCsrfApiConsumerService
      .post$({
        relativeUrl: this.logoutUrl,
        body: {},
        isSilent: true,
      })
      .subscribe();

    const behaviorSubjectAuthenticationState = this
      .authenticationState$ as BehaviorSubject<AuthenticationJwtState | null>;
    if (behaviorSubjectAuthenticationState.value) {
      this.toastService.info$({
        message: this.i18nService.translate$('sharedAuthentication.loggedOut'),
      });
      this.navController.navigateRoot(['']).then(() => {
        window.location.reload();
      });
    }
    behaviorSubjectAuthenticationState.next(null);
    this.cookieService.delete(
      COOKIE_IS_AUTHENTICATED,
      /* path =*/ '/',
      /* domain = */ undefined,
      /* secure = */ true,
      /* sameSite = */ 'None',
    );
  }

  /**
   * Attempts to get/renew a new jwt token for this user.
   *
   * If succeed, observable will contain the new access token.
   *
   * Otherwise, observable will be set to EMPTY, and:
   *
   * - If user is not logged in already, show notification and redirect.
   *
   * - If token is invalid, logout user, show notification, and redirect.
   */
  renewJwtToken$(): Observable<AuthenticationJwtState> {
    return this.bormaDagoCsrfApiConsumerService
      .post$<AuthenticationJwtState>({
        relativeUrl: this.jwtTokenUrl,
        // all information needed should already be in the cookies
        body: {},
        errorCodesToHandleManually: this.JWT_ERROR_CODES_TO_HANDLE_MANUALLY,
      })
      .pipe(
        // on error, log out user
        catchError(() => {
          this.logout();
          return EMPTY;
        }),
        tap((authenticationJwtState) => {
          this.setJwtState(authenticationJwtState);
        }),
      );
  }
}
