import { NgModule } from '@angular/core';

import {
  provideTranslocoLocale,
  TranslocoLocaleModule,
} from '@ngneat/transloco-locale';
import { Language } from '@ts/shared/i18n/util-constants';

const langToLocaleMapping: { [L in Language]: string } = {
  en: 'en-US',
  id: 'id-ID',
};

@NgModule({
  imports: [TranslocoLocaleModule],
  providers: [
    provideTranslocoLocale({ langToLocaleMapping: langToLocaleMapping }),
  ],
})
export class SharedDateTimeUtilShellModule {}
