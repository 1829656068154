import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  PRIMARY_OUTLET,
  RouterStateSnapshot,
} from '@angular/router';

import {
  BormadagoMetaTagStrategyService,
  MetaTagName,
} from './meta-tag.service';

@Injectable({
  providedIn: 'root',
})
export class MetaTagStrategyService {
  constructor(
    private bormadagoMetaTagStrategyService: BormadagoMetaTagStrategyService,
  ) {}

  setMetaTag(snapshot: RouterStateSnapshot) {
    let meta: Record<MetaTagName, string> | undefined;

    // this weird code are copied from angular
    // https://github.com/angular/angular/blame/c62b2dae8c4653996969df992e8f88887e7c83c4/packages/router/src/page_title_strategy.ts#L48
    let route: ActivatedRouteSnapshot | undefined = snapshot.root;

    while (route !== undefined) {
      meta = route.data['meta'] ?? meta;
      route = route.children.find((child) => child.outlet === PRIMARY_OUTLET);
    }

    for (const key of this.bormadagoMetaTagStrategyService.META_TAG_NAMES) {
      const value = meta ? meta[key] : undefined;
      if (value) {
        const param = {
          metaTagName: key as MetaTagName,
          contentTranslationKey: value,
        };
        this.bormadagoMetaTagStrategyService.setMetaTag(param);
      } else {
        this.bormadagoMetaTagStrategyService.removeMetaTag(key);
      }
    }
  }
}
