import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';

import { I18nService } from '@ts/shared/18n/util-core';
import { EnvironmentService } from '@ts/shared/util-environment';

import { MetaTagStrategyService } from './meta-tag-strategy.service';

export const DEFAULT_TITLE = 'Bormadago.com';

@Injectable({
  providedIn: 'root',
})
export class BormadagoTitleStrategy extends TitleStrategy {
  constructor(
    private readonly titleService: Title,
    private i18nService: I18nService,
    private environmentService: EnvironmentService,
    private metaStrategy: MetaTagStrategyService,
  ) {
    super();
  }

  override async updateTitle(snapshot: RouterStateSnapshot): Promise<void> {
    const titleKey = this.buildTitle(snapshot);
    let subtitle = '';
    if (titleKey) {
      subtitle = await this.i18nService.translate$(titleKey);
    }
    this.setTitle(subtitle);

    /**
     * This is hack to set meta tag from router snapshot
     * because angular does not have meta strategy
     */
    this.metaStrategy.setMetaTag(snapshot);
  }

  setTitle(subtitle: string) {
    let title: string = DEFAULT_TITLE;
    if (this.environmentService.get().app === 'soladmin') {
      title = `Staff.${title}`;
    }

    if (subtitle) {
      title = `${subtitle} | ${title}`;
    }
    this.titleService.setTitle(title);
  }
}
