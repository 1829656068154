import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';
import { FormlyIonicModule } from '@ngx-formly/ionic';
import { FormlyFormFieldModule } from '@ngx-formly/ionic/form-field';
import { I18nService } from '@ts/shared/18n/util-core';
import { SharedFormUiFormScaffoldModule } from '@ts/shared/form/ui-form-scaffold';
import { SharedFormUiSubmitButtonModule } from '@ts/shared/form/ui-submit-button';
import { registerTranslateExtension } from '@ts/shared/form/util-i18n';
import { SharedFormWrapperUiIonFormFieldModule } from '@ts/shared/form/wrapper/ui-ion-form-field';

import { EmailFieldComponent } from './field/email/email.field.component';
import { PasswordFieldComponent } from './field/password/password.field.component';
import { PhoneNumberFieldComponent } from './field/phone-number/phone-number.field.component';
import { showFormErrorMessage } from './form-error-message';
import { FormlyUiComponent } from './formly-ui/formly-ui.component';

@NgModule({
  imports: [
    CommonModule,
    FormlyFormFieldModule,
    FormlyIonicModule,
    FormlyModule.forRoot({
      types: [
        {
          name: 'email',
          component: EmailFieldComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'password',
          component: PasswordFieldComponent,
        },
        {
          name: 'phone-number',
          component: PhoneNumberFieldComponent,
          wrappers: ['form-field'],
        },
      ],
      validationMessages: [{ name: 'other', message: showFormErrorMessage }],
    }),
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    SharedFormUiFormScaffoldModule,
    SharedFormUiSubmitButtonModule,
    SharedFormWrapperUiIonFormFieldModule,
    TranslocoModule,
  ],
  declarations: [
    EmailFieldComponent,
    PasswordFieldComponent,
    PhoneNumberFieldComponent,
    FormlyUiComponent,
  ],
  exports: [
    EmailFieldComponent,
    PasswordFieldComponent,
    PhoneNumberFieldComponent,
    FormlyUiComponent,
  ],
  providers: [
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: registerTranslateExtension,
      deps: [I18nService],
    },
  ],
})
export class SharedFormUiFormlyModule {
  static forRoot(): ModuleWithProviders<SharedFormUiFormlyModule> {
    return {
      ngModule: SharedFormUiFormlyModule,
      providers: [
        {
          provide: FORMLY_CONFIG,
          multi: true,
          useFactory: registerTranslateExtension,
          deps: [I18nService],
        },
      ],
    };
  }
}
