import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';

import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyIonicModule } from '@ngx-formly/ionic';
import { SharedFormUiFormlyModule } from '@ts/shared/form/ui-formly';
import { SharedFormWrapperUiIonFormFieldModule } from '@ts/shared/form/wrapper/ui-ion-form-field';
import { SharedUiAwaitSpinnerModule } from '@ts/shared/ui-await-spinner';

import { AddressUiFieldComponent } from './address-ui/address-ui.field.component';
import { AddressFieldComponent } from './address/address.field.component';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
    IonicModule,
    GoogleMapsModule,
    SharedFormUiFormlyModule,
    FormlyIonicModule,
    ReactiveFormsModule,
    SharedUiAwaitSpinnerModule,
    SharedFormWrapperUiIonFormFieldModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'address',
          component: AddressFieldComponent,
        },
      ],
    }),
  ],
  declarations: [AddressFieldComponent, AddressUiFieldComponent],
  exports: [AddressFieldComponent],
})
export class SharedFormUiGoogleMapsModule {}
