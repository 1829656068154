import { CookieService } from 'ngx-cookie-service';
import { EMPTY, map, Observable } from 'rxjs';

import {
  AuthenticationService,
  COOKIE_IS_AUTHENTICATED,
} from './authentication.service';

/**
 * Initializes the authentication service at the start.
 */
export function initAuthenticationService(
  authenticationService: AuthenticationService,
  cookieService: CookieService,
): () => Observable<boolean> {
  return () => {
    if (cookieService.check(COOKIE_IS_AUTHENTICATED)) {
      return authenticationService.renewJwtToken$().pipe(map(() => true));
    } else {
      return EMPTY;
    }
  };
}
