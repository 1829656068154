import { Component, OnInit } from '@angular/core';

import { strict as assert } from 'assert';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { GoogleMapsScriptLoaderService } from '@ts/shared/google-maps/util-core';

import { AddressFieldModel } from '../address.field.model';

@Component({
  selector: 'ts-address-field',
  styleUrls: ['./address.field.component.scss'],
  templateUrl: './address.field.component.html',
  // can't use onPush strategy for form fields.
})
export class AddressFieldComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  isScriptLoaded$ = this.googleMapsApiLoaderService.isScriptLoaded$;
  addressFieldModelInitial?: AddressFieldModel;

  constructor(
    private googleMapsApiLoaderService: GoogleMapsScriptLoaderService,
  ) {
    super();
  }

  ngOnInit() {
    if (this.formControl.value) {
      this.addressFieldModelInitial = this.formControl.value;
    }
  }

  placeSelected(place: google.maps.places.PlaceResult | null) {
    if (!place) {
      this.formControl.setValue(null);
      this.formControl.setErrors({
        addressInvalid: true,
      });
    } else {
      assert(place.formatted_address);
      assert(place.place_id);
      assert(place.geometry);
      assert(place.geometry.location);
      const addressFieldModel: AddressFieldModel = {
        address: place.formatted_address,
        placeId: place.place_id,
        latLngLiteral: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
      };
      this.formControl.setValue(addressFieldModel);
    }
  }
}
