import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'ts-email-field',
  styleUrls: ['./email.field.component.scss'],
  templateUrl: './email.field.component.html',
  // can't use OnPush for form fields
})
export class EmailFieldComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  placeholder = 'your@email.com';

  ngOnInit() {
    this.formControl.addValidators(Validators.email);
  }
}
