import { map, Observable, take } from 'rxjs';

import { RecaptchaScriptLoaderService } from './recaptcha-script-loader.service';

/**
 * Loads the recaptcha script at the start. We need to this since apparently inserting
 * scripts may mess up the `async` pipe. E.g., without this,
 */
export function initRecaptchaScriptLoaderService(
  recaptchaScriptLoaderService: RecaptchaScriptLoaderService,
): () => Observable<boolean> {
  return () =>
    recaptchaScriptLoaderService.grecaptcha$.pipe(
      take(1),
      map(() => true),
    );
}
