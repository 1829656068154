import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { SpinnerUiComponent } from '../spinner-ui/spinner-ui.component';

interface tsAwaitSpinnerContext<T> {
  tsAwaitSpinner: T;
}

/**
 * A structural directive that conditionally includes a template when the expression
 * evaluates to any non-null value (including false, 0, etc),
 * and otherwise shows an `<ion-spinner>`.
 *
 * A [shorthand form](guide/structural-directives#asterisk) of the directive,
 * `*tsAwaitSpinner="condition"` can be used.
 *
 * @example
 * ```
 * <div *tsAwaitSpinner="$products | async as products">You have {{ products.length}} products.</div>
 * ```
 */
@Directive({
  selector: '[tsAwaitSpinner]',
})
export class AwaitSpinnerDirective<T = unknown> {
  @Input()
  set tsAwaitSpinner(condition: T) {
    if (condition !== null && condition !== undefined) {
      this.viewContainerRef.clear();
      this.viewContainerRef.createEmbeddedView(this.templateRef, {
        tsAwaitSpinner: condition,
      });
    } else {
      this.viewContainerRef.clear();
      this.viewContainerRef.createComponent(SpinnerUiComponent);
    }
  }

  constructor(
    private templateRef: TemplateRef<tsAwaitSpinnerContext<T>>,
    private viewContainerRef: ViewContainerRef,
  ) {}

  /**
   * Assert the correct type of the expression bound to the `ngIf` input within the template.
   *
   * The presence of this static field is a signal to the Ivy template type check compiler that
   * when the `NgIf` structural directive renders its template, the type of the expression bound
   * to `ngIf` should be narrowed in some way. For `NgIf`, the binding expression itself is used to
   * narrow its type, which allows the strictNullChecks feature of TypeScript to work with `NgIf`.
   */
  static ngTemplateGuard_tsAwaitSpinner: 'binding';

  /**
   * Asserts the correct type of the context for the template that `NgIf` will render.
   *
   * The presence of this method is a signal to the Ivy template type-check compiler that the
   * `NgIf` structural directive renders its template with a specific context type.
   */
  static ngTemplateContextGuard<T>(
    dir: AwaitSpinnerDirective<T>,
    ctx: tsAwaitSpinnerContext<T>,
  ): ctx is tsAwaitSpinnerContext<
    Exclude<T, false | 0 | '' | null | undefined>
  > {
    return true;
  }
}
