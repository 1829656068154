import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { AwaitSpinnerDirective } from './await-spinner/await-spinner.directive';
import { SpinnerUiComponent } from './spinner-ui/spinner-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [AwaitSpinnerDirective, SpinnerUiComponent],
  exports: [AwaitSpinnerDirective, SpinnerUiComponent],
})
export class SharedUiAwaitSpinnerModule {}
