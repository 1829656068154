import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';

import { IonFormFieldHelpUiComponent } from './ion-form-field-help-ui/ion-form-field-help-ui.component';
import { IonFormFieldLabelUiComponent } from './ion-form-field-label-ui/ion-form-field-label-ui.component';
import { IonFormFieldValidationMessageUiComponent } from './ion-form-field-validation-message-ui/ion-form-field-validation-message-ui.component';
import { IonFormFieldWrapperUiComponent } from './ion-form-field-wrapper-ui/ion-form-field-wrapper-ui.component';
import { IonFormNonFieldErrorsUiComponent } from './ion-form-non-field-errors-ui/ion-form-non-field-errors-ui.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    FormlyModule.forChild({
      wrappers: [
        {
          // we override the default form-field
          // see: https://github.com/ngx-formly/ngx-formly/issues/1202#issuecomment-424846270
          name: 'form-field',
          component: IonFormFieldWrapperUiComponent,
        },
      ],
    }),
  ],
  declarations: [
    IonFormFieldWrapperUiComponent,
    IonFormFieldLabelUiComponent,
    IonFormFieldValidationMessageUiComponent,
    IonFormNonFieldErrorsUiComponent,
    IonFormFieldHelpUiComponent,
  ],
  exports: [
    IonFormFieldWrapperUiComponent,
    IonFormFieldLabelUiComponent,
    IonFormFieldValidationMessageUiComponent,
    IonFormNonFieldErrorsUiComponent,
    IonFormFieldHelpUiComponent,
  ],
})
export class SharedFormWrapperUiIonFormFieldModule {}
