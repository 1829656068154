import { CookieService } from 'ngx-cookie-service';

import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';

import { initAuthenticationService } from './authentication-init';
import { AuthenticationService } from './authentication.service';

@NgModule({
  imports: [CommonModule],
})
export class SharedAuthenticationDataAccessModule {
  static forRoot(): ModuleWithProviders<SharedAuthenticationDataAccessModule> {
    return {
      ngModule: SharedAuthenticationDataAccessModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: initAuthenticationService,
          deps: [AuthenticationService, CookieService],
          multi: true,
        },
      ],
    };
  }
}
