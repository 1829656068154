import { formatDate } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { strict as assert } from 'assert';
import { Subscription } from 'rxjs';

import { IonPopover } from '@ionic/angular';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { FormlyFieldProps } from '@ngx-formly/ionic/form-field';

export interface DateTimeProps extends FormlyFieldProps {
  // default is date-time by IonDateTime so if you want datetime no need to send this
  presentation: 'date-time' | 'date';
  showDefaultButton?: boolean;
}

@Component({
  selector: 'ts-date-time-form-field-ui',
  templateUrl: './date-time-form-field-ui.component.html',
  styleUrls: ['./date-time-form-field-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateTimeFormFieldUiComponent
  extends FieldType<FieldTypeConfig<DateTimeProps>>
  implements OnInit, OnDestroy
{
  @ViewChild('popover') popover!: IonPopover;

  dateTimeFormControl!: FormControl<string | null>;
  dateTimeDisplayFormControl!: FormControl<string | null>;

  isoStringFormat = 'yyyy-MM-ddTHH:mm:ssZZZZZ';
  isoDateFormat = 'yyyy-MM-dd';
  // TODO(farid): formatDate only work with en locale not even id, fr and other locale works,
  // will figure it out later why and use i18nService to get the locale
  locale = 'en';
  subscription?: Subscription;

  ngOnInit() {
    this.dateTimeFormControl = new FormControl<string | null>(null);
    this.dateTimeDisplayFormControl = new FormControl<string | null>(null);

    this.subscription = this.dateTimeFormControl.valueChanges.subscribe(
      (value) => {
        if (value) {
          this.dateTimeDisplayFormControl.setValue(
            this.getIsoDateTimeFormat(value),
          );
        } else {
          this.dateTimeDisplayFormControl.setValue(value);
        }
      },
    );

    const value = this.formControl.value;
    if (value === undefined || value === null) {
      this.dateTimeFormControl.setValue(null);
    } else {
      const timestampMilliseconds = Math.trunc(value / 1000);

      this.dateTimeFormControl.setValue(
        this.getIsoDateTimeFormat(timestampMilliseconds),
      );
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  popoverShow(event: Event) {
    this.popover.event = event;
    this.popover.present();
  }

  setSelectedDate() {
    const value = this.dateTimeFormControl.value;
    if (value) {
      const dateTimestamp = new Date(value).getTime() * 1000;
      this.formControl.setValue(dateTimestamp);
    } else {
      this.formControl.setValue(null);
    }
    this.popover.dismiss();
  }

  private getIsoDateTimeFormat(value: string | number): string {
    assert(this.props.presentation);
    switch (this.props.presentation) {
      case 'date': {
        return formatDate(value, this.isoDateFormat, this.locale);
      }
      case 'date-time': {
        return formatDate(value, this.isoStringFormat, this.locale);
      }
    }
  }
}
