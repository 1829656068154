import { Injectable } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';

import { Platform } from '@ionic/angular';
import { IonSizeModifier } from '@ts/shared/util-ionic';

export type MobilePlatform = 'ios' | 'android';

const MOBILE_PLATFORM_DICT: {
  [mobilePlatform in MobilePlatform]: null;
} = { ios: null, android: null };

/**
 * Wrapper for ion platform, for getting platform information.
 */
@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  TOUCH_PLATFORMS: Array<Parameters<Platform['is']>[0]> = [
    'mobile',
    'mobileweb',
    'phablet',
    'tablet',
  ];

  MOBILE_PLATFORMS = Object.keys(
    MOBILE_PLATFORM_DICT,
  ) as readonly MobilePlatform[];

  /**
   * https://ionicframework.com/docs/layout/css-utilities#ionic-breakpoints
   */
  ION_SIZE_BREAKPOINTS: Record<IonSizeModifier, number> = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  };

  constructor(
    private platform: Platform,
    private cookieService: CookieService,
  ) {}

  /**
   * Whether the user is on a mobile device, regardless of app or web app.
   */
  isMobile(): boolean {
    return this.platform.is('mobileweb');
  }

  /**
   * Get platform of user mobile device
   */
  getMobilePlatform(): MobilePlatform | undefined {
    for (const mobilePlatform of this.MOBILE_PLATFORMS) {
      if (this.platform.is(mobilePlatform)) return mobilePlatform;
    }
    return undefined;
  }

  /**
   * Whether the user is on the application.
   *
   * This cookie will be hard-coded and set by our application when starting the application.
   */
  isApplication(): boolean {
    return this.cookieService.check('bormadago__is_app');
  }

  /**
   * Whether user is using a touch only device, i.e., no hovers.
   */
  isTouch(): boolean {
    return this.TOUCH_PLATFORMS.some((platform) => this.platform.is(platform));
  }

  getScreenWidthPx(): number {
    return this.platform.width();
  }

  isScreenMinWidth(size: IonSizeModifier): boolean {
    return this.getScreenWidthPx() >= this.ION_SIZE_BREAKPOINTS[size];
  }

  isScreenMaxWidth(size: IonSizeModifier): boolean {
    return this.getScreenWidthPx() <= this.ION_SIZE_BREAKPOINTS[size];
  }
}
