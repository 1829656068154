import { ConfigOption } from '@ngx-formly/core';
import { OrderStatusI18nService } from '@ts/order/shared/util-status-i18n';

import { OrderStatusPresetProvider } from './order-status.preset';

export function registerOrderStatusPreset(
  orderStatusI18nService: OrderStatusI18nService,
): ConfigOption {
  return {
    presets: [
      {
        name: 'orderStatus',
        config: new OrderStatusPresetProvider(orderStatusI18nService),
      },
    ],
  };
}
