import { strict as assert } from 'assert';
import { Observable, shareReplay, switchMap, take } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  ApiConsumerCallParams,
  ApiConsumerCallParamsWithBody,
  ApiConsumerService,
} from '@ts/shared/api/data-access-api-consumer';
import { BormaDagoApiConsumerService } from '@ts/shared/api/data-access-borma-dago-api-consumer';

import { BormaDagoCsrfStreamService } from './borma-dago-csrf-stream.service';
import { CsrfResponse } from './csrf.model';

export const POST_ONLY_ERROR = 'Can only use csrf with POST';

/**
 * Api consumer that also supplements the csrf token in the body.
 */
@Injectable({
  providedIn: 'root',
})
export class BormaDagoCsrfApiConsumerService implements ApiConsumerService {
  csrf$: Observable<CsrfResponse> =
    this.bormaDagoCsrfStreamService.truthyEntity$.pipe(take(1), shareReplay(1));

  constructor(
    private bormaDagoApiConsumerService: BormaDagoApiConsumerService,
    private bormaDagoCsrfStreamService: BormaDagoCsrfStreamService,
  ) {}

  private call$<T>(params: ApiConsumerCallParams): Observable<T> {
    assert(params.body);
    assert(!('csrf' in params.body));

    // fetch csrf token
    return this.csrf$.pipe(
      switchMap(({ csrf }) => {
        return this.bormaDagoApiConsumerService.call$<T>({
          ...params,
          body: {
            ...params.body,
            csrf,
          },
          httpOptions: {
            withCredentials: true,
          },
        });
      }),
    );
  }

  get$<T>(): Observable<T> {
    throw new Error(POST_ONLY_ERROR);
  }
  delete$<T>(): Observable<T> {
    throw new Error(POST_ONLY_ERROR);
  }
  post$<T>(params: ApiConsumerCallParamsWithBody): Observable<T> {
    return this.call$<T>({ ...params, method: 'post' });
  }
  put$<T>(): Observable<T> {
    throw new Error(POST_ONLY_ERROR);
  }
  patch$<T>(): Observable<T> {
    throw new Error(POST_ONLY_ERROR);
  }
}
