import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyIonicModule } from '@ngx-formly/ionic';

import { DateTimeFormFieldUiComponent } from './date-time-form-field-ui/date-time-form-field-ui.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormlyIonicModule,
    ReactiveFormsModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'date-time',
          component: DateTimeFormFieldUiComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
  ],
  declarations: [DateTimeFormFieldUiComponent],
  exports: [DateTimeFormFieldUiComponent],
})
export class SharedFormUiDateTimeModule {}
